import React,{useState} from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import Div100vh from "react-div-100vh";

function IndexPage( {data}) {
  const [visible, setVisible] = useState(false)

  return (
    <Layout>
      <SEO
        // keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Thomas Grau"
      />
      {/* <Div100vh className="flex flex-col rfs-pt-12 landscape:flex-row md:flex-row nice-bg-lin portrait:nice-bg-grad"> */}
      <Div100vh className="flex flex-col rfs-pt-12 landscape:flex-row md:flex-row">
            
            <div className="self-center rfs-px-6 landscape:w-1/2 md:w-1/2 md:rfs-pl-6 lg:rfs-pl-24 rfs-my-16 ">
                    <h1 className="leading-none rfs-text-6rm">
                      {/* Hallo, ich&nbsp;bin <span className="marked">Thomas&nbsp;Grau</span>. */}
                      Hallo, ich&nbsp;bin <span className="bgbold">Thomas&nbsp;Grau.</span>
                      </h1>
                  <p className="font-light leading-tight rfs-text-xl md:rfs-text-2xl landscape:rfs-text-xl lg:rfs-text-3xl">
                      Maschinenbau-Ingenieur mit Passion für neue Technologien, Online Marketing und das Internet.
                      </p>

            </div>

            <div className="f-100 gpic">
              <Img
                className=""
                // fixed={data.imageDesktop.childImageSharp.fixed} 
                fluid={data.imageFluid.childImageSharp.fluid} 
                imgStyle={{ objectFit: 'contain', objectPosition: 'bottom right' }}
                // , filter: 'drop-shadow(0 0 0.2rem #333)'
                />
            </div>

            {/* <div className="w-full text-white bg-black">
              <h1>Hallo 1234</h1>
            </div> */}

        </Div100vh>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query {
    imageFluid: file(relativePath: { eq: "grau_thomas.png" }) {
      childImageSharp {
            # i.e. the max width of your container is 700 pixels.
            #
            # Other options include maxHeight (set both maxWidth and maxHeight to crop),
            # grayscale, duotone, rotate, etc.
            fluid(quality: 90, 
            maxWidth: 700
                  # maxWidth: 384,
                  # sizes: "(min-width: 768px) 384px, 256px",
                  # srcSetBreakpoints: [100,200,256,384]
                  ){
              # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
              # ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluid_withWebp_noBase64
              # ...GatsbyImageSharpFluid
              # ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
      }
    },
    imageMobile: file(relativePath: { eq: "grau_thomas.png" }) {
      childImageSharp {
        fixed(
           quality: 90, 
           width: 256
          ){
              ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    },
    imageDesktop: file(relativePath: { eq: "grau_thomas.png" }) {
      childImageSharp {
        fixed(
           quality: 90, 
           width: 700
          ){
              ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
  }
`